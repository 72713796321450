

.category-btn {
  position: absolute;
  -webkit-transform: translateX(-50%);
  left: 50%;
  bottom: 30px;

  width: 50%;
  height: 40px;
  background-color: rgba(255,255,255,0.95) !important;
  color: #666666 !important;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

.rodal-dialog {
  top: 55px;
  background-color: rgb(0, 0, 0);
  opacity: 0.8;
  width: 100%;
}
